import { useQuery } from "@apollo/client"
import { useMutation } from "@apollo/client"
import { FormControlLabel, IconButton, Snackbar, SnackbarContent } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import React from "react"
import { RouteChildrenProps } from "react-router"
import { SetEntryRolesMutation, SetEntryRolesMutationVariables } from "../../../__generated__/SetEntryRolesMutation"
import constants from "../../../common/constants"
import { REMOVE_ENTRY_FROM_POOL_MUTATION, SET_ENTRY_ROLES_MUTATION } from "../../App/queries"
import { ButtonSmallBase } from "../../components/Button"
import { FormButtonSpinnerBase } from "../../components/Form"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { ADMIN_MAINT_POOL_ENTRY_QUERY } from "../queries"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import { useStyles } from "./styles-maint"
import DataTable, { IDescribeResult, IDataTableColFunctionalities } from "../Database/DataTable"
import { fromGlobalId } from "../../../common/global-ids"
import { modifySQLresults } from "./Page-Activities"
import InfoCard from "./InfoCard"

const grantPoolManagerAccessToggle = async (memberId, isManager, setEntryRolesMutation) => {
  const result = await setEntryRolesMutation({
    variables: { memberId, roles: isManager ? [] : [constants.POOL_ROLES.MANAGER] },
  })
  return result
}

const removeEntry = async (entryId, removeEntryFromPoolMutation) => {
  return await removeEntryFromPoolMutation({
    variables: { entryId },
  })
}

function EntryForm({ entry, props }) {
  const { id, name } = entry
  const isManager = entry.roles.indexOf(constants.POOL_ROLES.MANAGER) > -1
  const [setEntryRolesMutation] = useMutation<SetEntryRolesMutation, SetEntryRolesMutationVariables>(SET_ENTRY_ROLES_MUTATION)
  const [removeEntryFromPoolMutation, { loading: loadingRemoveEntry }] = useMutation(REMOVE_ENTRY_FROM_POOL_MUTATION, {
    refetchQueries: ["ManagePlayersQuery", "AdminSQLMaintPoolsQuery"],
    awaitRefetchQueries: true,
  })

  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleRemovePlayerClick = () => {
    setOpen(true)
  }

  const handleRemovePlayerClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  const descriptionColumn: IDescribeResult = {
    Field: "description",
    Type: "json",
    Null: "YES",
    Key: "",
    Default: null,
    Extra: "",
    tableName: "Activity",
  }

  const dataTableColFunctionalities: IDataTableColFunctionalities = {
    goToId: false,
    highlightColJson: true,
    jsonColButton: true,
    searchCol: true,
  }

  return (
    <>
      <InfoCard maintenanceTitle={`Entry ID: ${id}`} maintenanceSubtitle={`Entry Name: ${name}`} />

      <br />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="div">
            Actions + Details
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Below will be all the Entry specific actions, with a breif description.
          </Typography>
          <Typography variant="h6" component="div">
            Grant Manager Access
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isManager}
                onChange={() => grantPoolManagerAccessToggle(entry.id, isManager, setEntryRolesMutation)}
                value={isManager}
                color={"primary"}
              />
            }
            label="Grant Manager Access"
          />

          <Typography variant="body2" component="div" color="textSecondary">
            <li>The Grant Manager Access Toggle, enables and disables the Manager role for each specific player.</li>
            <li>This toggle is only enabled for CBS Admins only, and all Admins must be signed in prior.</li>
          </Typography>
          <br />
          <Typography variant="h6" component="div">
            Remove a Player
          </Typography>

          <FormButtonSpinnerBase
            isValid={true}
            as={ButtonSmallBase}
            isSubmitting={loadingRemoveEntry}
            inert="Remove Player"
            onClick={(e) => {
              e.preventDefault()
              removeEntry(entry.id, removeEntryFromPoolMutation)
                .then(handleRemovePlayerClick)
                .then(() => window.setTimeout(() => props.history.replace(`/admin/maint/pool/${props.match.params.poolId}`), 4000))
            }}
            disabled={loadingRemoveEntry}
            status={"enabled"}
          />

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleRemovePlayerClose}
          >
            <SnackbarContent
              style={{
                backgroundColor: "#558b2f",
              }}
              message={"Sucessfully removed this Player from the Pool. Will redirect you back to the pool page shortly!"}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={handleRemovePlayerClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          </Snackbar>

          <Typography variant="body2" component="div" color="textSecondary">
            <li>This Action is only enabled for CBS Admins only, and all Admins must be signed in prior.</li>
            <li>The Remove a Player Button, allows CBS Admins to remove a Member/Entry from a pool.</li>
            <li>This Action does not remove all entries of a player from a pool.</li>
            <li>If this Entry is the last in its pool, this action will not delete the pool.</li>
            <li>To delete the pool, follow the 'Delete Pool' Action (TBD).</li>
          </Typography>
        </CardContent>
      </Card>
      <br />

      {/* NOTE fjp: Here I have to wait to merge my other pr and then have that InfoCard communicate to the FE that one can see a 
      specific entries activities by going to the entry page */}

      <DataTable
        {...props}
        poolId={id}
        isDeletable={true}
        isEditable={false}
        title={`${name} Activities`}
        tableNameFromProp="Activity"
        whitelistedColumns={[
          "id",
          "actor",
          "actorTableName",
          "actorTableId",
          "object",
          "objectTableName",
          "objectTableId",
          "verb",
          "target",
          "targetTableName",
          "targetTableId",
          "time",
          "createdAt",
          "updatedAt",
          "extra",
          "description",
        ]}
        excludeLayout={true}
        sqlWhereScopes={[
          `(actorTableName = "Entry" AND actor = '${id}') OR (objectTableName = "Entry" AND object = '${id}') OR (targetTableName = "Entry" AND target = '${id}') OR (objectTableName = "Entry" AND objectTableId IN (SELECT id FROM Entry WHERE Entry.poolId = ${
            fromGlobalId(id).id
          })) OR (targetTableName = "Entry" AND targetTableId IN (SELECT id FROM Entry WHERE Entry.poolId = ${fromGlobalId(id).id}))`,
        ]}
        virtualColumns={[descriptionColumn]}
        modifyResults={modifySQLresults}
        dataTableColFunctionalities={dataTableColFunctionalities}
      />

      <br />
    </>
  )
}

function EntryPage(props: RouteChildrenProps<any>) {
  const entryId = props.match?.params?.entryId
  const entriesQuery = useQuery(ADMIN_MAINT_POOL_ENTRY_QUERY, { variables: { entryId } })

  return (
    <Layout title={`Maintenance - Pool - Entry`}>
      {(entriesQuery.data?.entry && <EntryForm entry={entriesQuery.data.entry} props={props} />) || <LoadingView />}
    </Layout>
  )
}
export default EntryPage

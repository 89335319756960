import { useStyles } from "./styles-maint"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React from "react"

const InfoCard = (props) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body1" component="div">
          {props.maintenanceTitle}
        </Typography>
        <Typography variant="body1" component="div">
          {props.maintenanceSubtitle}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default InfoCard

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 20,
    },
    pos: {
      marginBottom: 20,
      fontSize: 15,
    },
    text: {
      "& > *": {
        margin: theme.spacing(1),
        width: "50ch",
      },
    },
  }),
)

// export const useStyles = makeStyles({
//   root: {
//     minWidth: 275,
//   },
//   bullet: {
//     display: "inline-block",
//     margin: "0 2px",
//     transform: "scale(0.8)",
//   },
//   title: {
//     fontSize: 20,
//   },
//   pos: {
//     marginBottom: 20,
//     fontSize: 15,
//   },
//   text: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '50ch',
//     },
//   },
// });
